import { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { FaTwitter } from "@react-icons/all-files/fa/FaTwitter";
import { FaFacebookF } from "@react-icons/all-files/fa/FaFacebookF";
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram";
import { FaSkype } from "@react-icons/all-files/fa/FaSkype";
import { FaLinkedinIn } from "@react-icons/all-files/fa/FaLinkedinIn";
import { FaChevronRight } from "@react-icons/all-files/fa/FaChevronRight";
import { Link } from "react-router-dom";

import {  useLocation } from "react-router-dom";

function Footer() {
  
  let location = useLocation();

  const [locpath, setLocpath] = useState('');
  
  useEffect(() => {
    setLocpath(location.pathname);
  }, [location]);
  return (
    <>
      {
        locpath === '/' &&
        <div className='bg-news' data-aos="zoom-in" data-aos-duration="500">
          <Container>
            <Row className='news-letter'>
              <h2>Join Our Newsletter</h2>
              <Form onSubmit={e=>e.preventDefault()} className='form-static'>
                <Row>
                  <Col md={12} className='form-holder'>
                    <Form.Control name="fullname" type="email"/>
                    <Button variant="primary" type="submit" className='cs-btn-form'>
                        Subscribe
                    </Button> 
                  </Col>
                </Row>
            </Form>
            </Row>
          </Container>
        </div>
      }
      <div className='footer-link'>
        <Container>
          <Row>
              <Col md={3}>
                  <h3>Double Mob Media</h3>
                  <p className='text-white footer-desc'>
                  FLAT/RM 705, 7/F FA YUEN COMMERICAL BUILDING NO.75,FA YUEN STREET, MONG KOK,KL, Hong Kong.
                  </p>
                  <ul className='info'>
                    <li><strong>Phone</strong>: <a href="tel:+85281926478">+852 8192 6478</a></li>
                    <li><strong>Email</strong>: <a href="mailto:support@doublemobmedia.com">support@doublemobmedia.com</a></li>
                  </ul>
              </Col>
              <Col md={3}>
                  <h6>
                    Useful Links
                  </h6>
                  <ul className='link'>
                    <li><Link to="/#home"><FaChevronRight className='footer-sublink'/> Home</Link></li>
                    <li><Link to="/#about"><FaChevronRight className='footer-sublink'/> About us</Link></li>
                    <li><Link to="/#partners"><FaChevronRight className='footer-sublink'/> Partners</Link></li>
                    <li><Link to="/terms-and-condition"><FaChevronRight className='footer-sublink'/> Terms of service</Link></li>
                    <li><Link to="/privacy-policy"><FaChevronRight className='footer-sublink'/> Privacy policy</Link></li>
                  </ul>
              </Col>
              <Col md={6}>
                  <h6>
                    Our Social Networks
                  </h6>
                  <ListGroup className=''>
                      <ListGroup.Item className='theme-color-2'>
                          <span className='sm-icons'><FaTwitter/></span>
                          <span className='sm-icons'><FaFacebookF /></span>
                          <span className='sm-icons'><FaInstagram /></span>
                          <span className='sm-icons'><FaSkype /></span>
                          <span className='sm-icons'><FaLinkedinIn /></span>
                      </ListGroup.Item>
                  </ListGroup>
              </Col>
            </Row>
          </Container>
      </div>
    </>
  )
}

export default Footer