import { React } from 'react'
import Hero from './Hero';
import About from './About';
import Partners from './Partners';
import Contact from './Contact';

function Home({ sectionRefs }) {
    
  return (
    <>
        <section className='hero' ref={sectionRefs[0]} id='home'>
        <Hero />
        </section>
        <section className='about' id="about" ref={sectionRefs[1]}>
        <About />
        </section>
        <section className='partners' id="partners" ref={sectionRefs[2]}>
        <Partners />
        </section>
        <section className='contact'  id="contact" ref={sectionRefs[3]}>
        <Contact />
        </section>
    </>
  )
}

export default Home