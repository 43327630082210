import { useEffect, React, useState, useRef } from 'react';
import './assets/css/Reset.css';
import Header from './component/Header';
import useScrollSpy from 'react-use-scrollspy';
import ScrollToTop from "react-scroll-to-top";
import Footer from './component/Footer';
import Copyright from './component/Copyright';

import { Routes, Route, useLocation } from "react-router-dom";
import Terms from './component/Terms';
import Home from './component/Home';
import PrivacyPolicy from './component/PrivacyPolicy';
import AOS from 'aos';
import 'aos/dist/aos.css';


function App() {
  const sectionRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  const activeSection = useScrollSpy({
    sectionElementRefs: sectionRefs,
    offsetPx: -100,
  });

  const [scroll, setScroll] = useState(false);
  let location = useLocation();
  useEffect(() => {
    AOS.init({
      offset: 150,
      duration: 600,
      easing: 'ease-in-sine',
      delay: 100,
      disable: 'mobile'
    });
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
    if (location.hash === '') {
      window.scrollTo(0, 0);
    }
    else {
      setTimeout(() => {
        const id = location.hash.toString().replace('#', '');
        console.log(location.hash);
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [location]);
  return (
    <div className="App">
      <header className={scroll ? "App-header fixed-top bg-scroll" : "App-header fixed-top"}>
        <Header activeSection={activeSection} />
      </header>
      <main>
        <Routes>
            <Route index path="/" element={<Home sectionRefs={sectionRefs} />} />
            <Route  path="/terms-and-condition" element={<Terms />} />
            <Route  path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </main>
      <footer>
        <div className='f-col'><Footer /></div>   
        <div className='copyright'><Copyright/></div>
      </footer>
      <ScrollToTop smooth color="#fff" className='text-center'/>
    </div>
  );
}

export default App;
