import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from "react-router-dom";

function Header({ activeSection }) {
  return (
    <>
        {['lg'].map((expand) => (
        <Navbar key={expand} expand={expand} className="bg-body-tertiary" collapseOnSelect>
          <Container>
            <Navbar.Brand href="/">DOUBLEMOBMEDIA</Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                DOUBLEMOBMEDIA
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Nav.Link as={Link} eventKey="1" to="/#home" className={activeSection === 0 ? "theme-color item--active" : "theme-color"}>Home</Nav.Link>
                  <Nav.Link as={Link} eventKey="2" to="/#about" className={activeSection === 1 ? "theme-color item--active" : "theme-color"}>ABOUT</Nav.Link>
                  <Nav.Link as={Link} eventKey="3" to="/#partners" className={activeSection === 2 ? "theme-color item--active" : "theme-color"}>PARTNERS</Nav.Link>
                  <Nav.Link as={Link} eventKey="4" to="/#contact" className={activeSection === 3 ? "theme-color item--active" : "theme-color"}>Contact</Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  )
}

export default Header