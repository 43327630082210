import { React, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

function Partners() {
    const [show, setShow] = useState([]);
    const [brand, setBrand] = useState([]);
    useEffect(() => {
        setShow(['logo_aol', 'logo_facebook', 'logo_forbes', 'logo_fox', 'logo_google', 'logo_instagram', 'logo_taboola', 'logo_yahoo']);
        setBrand(['dfo-global-logo', 'm4trix-logo2'])
    }, []);
    return (
        <Container>
            <Row>
                <Col data-aos="fade-in" data-aos-duration="500">
                    <h2 className='text-center offers ser'>
                        TRUSTED BRAND PARTNERSHIPS
                    </h2>
                </Col>
            </Row>

            <Row className='brand-section align-items-md-center' data-aos="fade-up" data-aos-duration="500">
                {
                    brand.map((res, id)=>(
                        <Col md={6}  key={id} className='p-3'>
                            <p className='text-center'><img src={`assets/img/${res}.png`} alt={res} className='brand-img'/></p>
                        </Col>
                    
                    ))
                }
            </Row>

            <Row  className="partners-section justify-content-lg-center post-grid">
                <Col md={12}  data-aos="fade-in" data-aos-duration="500">
                    <h2 className='text-center offers ser'>
                        EXPLORE SOME OF OUR TRAFFIC PARTNERS
                    </h2>
                    
                    <p className='text-center'>From user content and reviews to cart optimization and sales, specialized solutions for e-commerce products are available.</p>
                </Col>
                {
                    show.map((res, id)=>(
                        <Col xs={12} sm={6} md={6} lg={3} key={id} className='p-3'  data-aos="fade-up" data-aos-duration={`${id}00`}>
                            <Card style={{width:"100%", height:"100%"}}>
                                <div className='card-img-extra-paddng text-center'><Card.Img variant="top" src={`assets/img/${res}.png`} /></div>
                            </Card>
                        </Col>
                    ))
                }
            </Row>
        </Container>
    )
}

export default Partners