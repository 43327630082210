import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaMobileAlt } from "@react-icons/all-files/fa/FaMobileAlt";
import { FaRegEnvelope } from "@react-icons/all-files/fa/FaRegEnvelope";
import { FaMapMarkerAlt } from "@react-icons/all-files/fa/FaMapMarkerAlt";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

function Contact() {
  return (
    <Container>
        <Row>
            <Col md={12} data-aos="fade-up" data-aos-duration="500">
                <h2 className='text-center'>CONTACT US</h2>
                <iframe className='iframe-map' id="iframe" src="https://maps.google.com/maps?q=LUCKY%20CENTRE,%20%20165-171%20WANCHAI%20ROAD,%20WANCHAI,%20HONG%20KONG&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed" allowFullScreen='0'></iframe>
            </Col>
        </Row>
        <Row className='align-items-md-center'>
            <Col md={6} lg={4} sm={12} data-aos="fade-right" data-aos-duration="500">
                <ul className='item-list-cs'>
                    <li>
                        <Row>
                            <Col md={2} sm={12}>
                                <div className='icon-section'><FaMapMarkerAlt className='icon-cs'/></div>
                                </Col>
                                <Col md={10} sm={12}>
                                <h3>Location:</h3>
                                <p>FLAT/RM 705, 7/F FA YUEN COMMERICAL BUILDING NO.75,FA YUEN STREET, MONG KOK,KL, Hong Kong.</p>
                            </Col>
                        </Row>
                    </li>
                    <li>
                        <Row>
                            <Col md={2} sm={12}>
                                <div className='icon-section'><FaRegEnvelope className='icon-cs'/></div>
                                </Col>
                                <Col md={10} sm={12}>
                                <h3>Email:</h3>
                                <p><a href="mailto:support@doublemobmedia.com">support@doublemobmedia.com</a></p>
                            </Col>
                        </Row>
                    </li>
                    <li>
                        <Row>
                            <Col md={2} sm={12}>
                                <div className='icon-section'><FaMobileAlt className='icon-cs'/></div>
                                </Col>
                                <Col md={10} sm={12}>
                                <h3>Call:</h3>
                                <p><a href="tel:+85281926478">+852 8192 6478</a></p>
                            </Col>
                        </Row>
                    </li>
                </ul>
            </Col>
            <Col md={6} lg={8} sm={12} data-aos="fade-left" data-aos-duration="500">
                <Form onSubmit={e=>e.preventDefault()} className='form-static'>
                    <Row>
                        <Col md={6}>
                        <Form.Control placeholder="Your Name" name="fullname" type="text"/>
                        </Col>
                        <Col md={6}>
                        <Form.Control placeholder="Your Email" name="email" type="email" />
                        </Col>
                        <Col md={12}>
                        <Form.Control placeholder="Subject" name="subject" type="text" />
                        </Col>
                        <Col md={12}>
                        </Col>
                        <Col md={12}>
                            <Form.Control
                            as="textarea"
                            placeholder="Message"
                            style={{ height: '100px' }}
                            />
                        </Col>
                        
                        <Col md={12} className='text-center'>
                            <Button variant="primary" type="submit" className='mt-2 cs-btn-form'>
                                Send Message
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>
    </Container>
  )
}

export default Contact