import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaMobileAlt } from "@react-icons/all-files/fa/FaMobileAlt";
import { FaFingerprint } from "@react-icons/all-files/fa/FaFingerprint";
import { FaShoppingBasket } from "@react-icons/all-files/fa/FaShoppingBasket";
import AnimatedNumber from "animated-number-react";

function About() {
  return (
    <Container>
      <Row className='about-dmm'>
        <Col lg={5} md={12} data-aos="fade-right" data-aos-duration="500">
            <img src="./assets/img/pexels-fauxels-3184287.jpg" alt="Worldmap" />
        </Col>
        <Col lg={7} md={12}>
            <h2 className='theme-color' data-aos="fade-left" data-aos-duration="500">
            About DoubleMobMedia:
            </h2>
            <p data-aos="fade-left" data-aos-duration="500">
            Founded in 2015, DoubleMobMedia in an ever-evolving online advertising company, We Specialized in promoting e-commerce products/Android Apps/Browser extensions/Lead generation Offers. With over 9 years experiences, we have acquired high-quality consumers and substantial ROI for our advertisers. Now we focus on Lead generation and search traffic monetization.
            </p>
            <ul className='item-list-cs'>
              <li data-aos="fade-up" data-aos-duration="500">
                <Row>
                  <Col md={2} sm={12}>
                    <div className='icon-section'><FaMobileAlt className='icon-cs'/></div>
                  </Col>
                  <Col md={10} sm={12}>
                    <h3>Search Traffic Monetization</h3>
                    <p>We're looking for Premium BHS/Yahoo/N2S Feeds</p>
                  </Col>
                </Row>
              </li>
              <li data-aos="fade-up" data-aos-duration="500">
                <Row>
                  <Col md={2} sm={12}>
                    <div className='icon-section'><FaFingerprint className='icon-cs'/></div>
                  </Col>
                  <Col md={10} sm={12}>
                    <h3>Extension Traffic</h3>
                    <p>Highest RPM guarantee in the industry.</p>
                  </Col>
                </Row>
              </li>
              <li data-aos="fade-up" data-aos-duration="500">
                <Row>
                  <Col md={2} sm={12}>
                    <div className='icon-section'><FaShoppingBasket className='icon-cs'/></div>
                  </Col>
                  <Col md={10} sm={12}>
                    <h3>Affiliate Marketing</h3>
                    <p>Lead Generation /E-Commerce </p>
                  </Col>
                </Row>
              </li>
            </ul>
        </Col>
      </Row>

      <Row className='about-company'>
        <Col md={12} data-aos="fade-up" data-aos-duration="500">
            <h2 className='theme-color text-center'>
            Why Choose US?
            </h2>
            <p className='text-center'>Long-term partnerships with many of the leading companies in the field enable our technology to fulfill continuously and evolvingly a wide range of marketers' needs. We are committed to providing professional service, timely response, high-quality traffic.</p>
        </Col>
        <Col xs={6} sm={6} md={3} className='animated-number' data-aos="fade-in" data-aos-duration="500">
          <p className='text-center number'><AnimatedNumber value={100} formatValue={(value) => value.toFixed(0)} />+</p>
          <p className='text-center desc'>Categories</p>
        </Col>
        <Col xs={6}  sm={6}  md={3} className='animated-number' data-aos="fade-in" data-aos-duration="500">
          <p className='text-center number'><AnimatedNumber value={10} formatValue={(value) => value.toFixed(0)} />M+</p>
          <p className='text-center desc'>Monthly Visitors</p>
        </Col>
        <Col xs={6}  sm={6}  md={3} className='animated-number' data-aos="fade-in" data-aos-duration="500">
          <p className='text-center number'><AnimatedNumber value={25} formatValue={(value) => value.toFixed(0)} />M+</p>
          <p className='text-center desc'>Leads & Sales</p>
        </Col>
        <Col xs={6}  sm={6}  md={3} className='animated-number' data-aos="fade-in" data-aos-duration="500">
          <p className='text-center number'><AnimatedNumber value={16} formatValue={(value) => value.toFixed(0)} /></p>
          <p className='text-center desc'>Hard Workers</p>
        </Col>
      </Row>
    </Container>
  )
}

export default About